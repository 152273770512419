<div class="form-card">
    @if (isFromSetPw) {
    <form class="d-flex justify-content-center align-items-center gap-2" [formGroup]="passwordForm">
        <div class="mt-30px">
            <span class="p-float-label cus-float-label">
                <p-password class="cus-password" formControlName="password" [toggleMask]="true"></p-password>
                <label>password</label>
            </span>
            <div
                *ngIf="passwordForm.get('password').invalid && (passwordForm.get('password').dirty || passwordForm.get('password').touched)">
                <div class="invalid-feedback" *ngIf="passwordForm.get('password').errors['required']">Password is
                    required</div>
                <div class="invalid-feedback" *ngIf="passwordForm.get('password').errors['minlength']">Password must be
                    at least 6 characters long</div>
            </div>
        </div>
        <div class="mt-30px">
            <span class="p-float-label cus-float-label">
                <p-password class="cus-password" formControlName="confirmPassword" [toggleMask]="true"></p-password>
                <label>Confirm Password</label>
            </span>
            <div
                *ngIf="passwordForm.get('confirmPassword').invalid && (passwordForm.get('confirmPassword').dirty || passwordForm.get('confirmPassword').touched)">
                <div class="invalid-feedback" *ngIf="passwordForm.get('confirmPassword').errors['required']">Confirm
                    Password is required</div>
                <div class="invalid-feedback"
                    *ngIf="!(passwordForm.get('confirmPassword').errors['required']) && (passwordForm.get('confirmPassword').errors['passwordsNotMatch'])">
                    Passwords do not match</div>
            </div>
        </div>
    </form>

    } @else {
    <form [formGroup]="contactForm">
        <div class="row gap-y-30px">
            <!-- <div class="col-6">
                    <span class="p-float-label cus-float-label">
                        <p-dropdown appendTo="body" class="w-full cmn-dropdown" [options]="accessList"
                        formControlName="access" placeholder="Select Source"></p-dropdown>
                        <label for="country-float-label"> Access </label>
                    </span>
                </div> -->

            <div class="col-6">
                @if(isFromSetting){
                <span class="p-float-label cus-float-label">
                    <input class="w-full" pInputText formControlName="role" />
                    <label>Role</label>
                </span>
                }
                @else{
                <span class="p-float-label cus-float-label">
                    <input class="w-full" pInputText formControlName="title" />
                    <label>Title</label>
                </span>
                }
            </div>

            <div class="col-6">
                <span class="p-float-label cus-float-label">
                    <input class="w-full" pInputText formControlName="firstName" />
                    <label>First Name</label>
                </span>
                <div *ngIf="contactForm.get('firstName').invalid && contactForm.get('firstName').touched"
                    class="invalid-feedback">
                    First Name is required
                </div>
            </div>

            <div class="col-6">
                <span class="p-float-label cus-float-label">
                    <input class="w-full" pInputText formControlName="lastName" />
                    <label>Last Name</label>
                </span>
                <div *ngIf="contactForm.get('lastName').invalid && contactForm.get('lastName').touched"
                    class="invalid-feedback">
                    Last Name is required
                </div>
            </div>

            <div class="col-6">
                <span class="p-float-label cus-float-label">
                    <input class="w-full" pInputText formControlName="email" />
                    <label>Email</label>
                </span>
                <div
                    *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                    <div class="invalid-feedback" *ngIf="contactForm.get('email').errors['email']">
                        Enter valid E-mail
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="position-relative w-full h-full">
                    <div (click)="imageInput.click()" class="image-doc-uploader">
                        <div (dragover)="onFileSelected($event)" (drop)="onFileSelected($event)">
                            <input style="display: none;" class="image-upload-input" #imageInput type="file"
                                accept="image/jpeg,image/png,image/jpg" (change)="onFileSelected($event)">
                            <div *ngIf="!imagePreview" class="drop-container">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_6223_1720)">
                                        <path
                                            d="M33.3272 14.7033C31.4785 7.33865 24.0097 2.86711 16.6451 4.71581C10.8898 6.16057 6.71076 11.1348 6.28018 17.053C2.19368 17.7269 -0.572818 21.5859 0.101093 25.6724C0.700168 29.3054 3.8481 31.966 7.53005 31.9513H13.7794V29.4516H7.53005C4.76894 29.4516 2.53059 27.2133 2.53059 24.4522C2.53059 21.6911 4.76894 19.4527 7.53005 19.4527C8.22036 19.4527 8.77991 18.8932 8.77991 18.2028C8.77366 11.9903 13.8049 6.949 20.0174 6.94283C25.3953 6.93744 30.0247 10.7394 31.065 16.0156C31.1677 16.5424 31.5952 16.9449 32.1274 17.0155C35.5443 17.5021 37.9198 20.6665 37.4333 24.0834C36.9964 27.1516 34.3766 29.4362 31.2774 29.4516H26.278V31.9513H31.2774C36.1094 31.9367 40.0146 28.0078 39.9999 23.1758C39.9877 19.1536 37.2346 15.6578 33.3272 14.7033Z"
                                            fill="#071871" />
                                        <path
                                            d="M19.142 19.8146L14.1426 24.8141L15.9049 26.5764L18.7796 23.7142V35.7004H21.2793V23.7142L24.1415 26.5764L25.9038 24.8141L20.9043 19.8146C20.4168 19.33 19.6296 19.33 19.142 19.8146Z"
                                            fill="#071871" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6223_1720">
                                            <rect width="40" height="40" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <label for="fileInput" class="drop-label pt-3">Upload rep photo</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="imagePreview" class="preview-img">
                        <img (error)="imgError($event)" [src]="imagePreview">
                    </div>
                    <p-button *ngIf="imagePreview" (onClick)="removeLogo()" icon="pi pi-times" class="remove-btn"
                        [rounded]="true" severity="danger"></p-button>
                </div>
            </div>

            <div class="col-6">
                <span class="p-float-label cus-float-label mb-4">
                    <input class="w-full" pInputText appNumbersOnly formControlName="phone" />
                    <label>Phone</label>
                </span>
            </div>
        </div>
    </form>
    }
    <div class="col-12 mt-4 pt-2 mx-auto">
        <div class="d-flex gap-5 max-w-400px mx-auto">
            <p-button class="w-full cmn-btn-secondary rounded-full" label="Cancel" (click)="closeModel()"
                [outlined]="true"></p-button>
            <p-button [disabled]="isDisabled" class="w-full cmn-btn-primary rounded-full" label="Save"
                (click)="addEditContact()"></p-button>
        </div>
    </div>
</div>
